@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-midnight-blue font-sans bg-center bg-repeat overflow-x-hidden bg-[length:30%] lg:bg-[length:4%];
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg');
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }

  h1 {
    @apply mt-8 mb-0 mx-auto font-bold text-5xl font-sans text-azure text-center;
  }

  h5 {
    @apply my-2 mb-8 mx-auto font-normal text-[1.3rem] font-sans text-azure text-center;
  }

  a {
    @apply no-underline;
  }

  aside.context {
    @apply text-center text-white leading-7;
  }
  aside.context a {
    @apply no-underline text-white py-[3px] px-0 border-b-[1px] border-dashed;
  }
  aside.context .explanation {
    @apply max-w-3xl mt-16 mb-0 mx-auto;
  }
}

@layer components {
  .periodic-table {
    @apply grid grid-rows-[_repeat(4,100px)] grid-cols-[_repeat(9,100px)] gap-2.5 m-auto max-w-7xl lg:justify-center overflow-x-visible overflow-y-hidden lg:overflow-visible px-16 pb-20;
  }
  .periodic-element {
    @apply p-1 relative z-[1] cursor-default transition-all duration-300 ease-in-out;
  }
  .periodic-element .periodic-element-inner {
    @apply bg-midnight-blue py-2.5 px-[15px] w-[_calc(100%-30px)] h-[_calc(100%-20px)] transition-[inherit] text-center box-content;
  }
  .periodic-element .sub-title {
    @apply font-bold text-[34px] leading-[1.3] font-sans mt-[0.3em] mx-0 mb-0 transition-all duration-800 ease-in delay-75;
  }
  .periodic-element .title {
    @apply font-medium text-xs font-sans -mt-[0.2em];
  }
  .periodic-element:after {
    @apply z-10 content-[attr(data-description)] bg-seashell text-night-rider w-[180px] absolute top-[80%] opacity-0 h-auto text-xs leading-[1.4] p-2.5 my-0 mr-0 -ml-11 rounded-md text-center scale-[0.3];
    transition: opacity 0.3s ease, top 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition-duration: 0.6s;
    -ms-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
  }
  .periodic-element:hover {
    @apply scale-[1.12] z-10;
  }
  .periodic-element:hover .periodic-element-inner {
    @apply bg-transparent;
  }
  .periodic-element:hover .sub-title,
  .periodic-element:hover .title {
    @apply text-nero bg-none;
  }
  .periodic-element:hover:after {
    @apply top-[105%] opacity-100 scale-100 -ml-[50px];
  }
  a.periodic-element {
    @apply cursor-pointer;
  }
  .social-media,
  .legend-table__text.social-media::before {
    @apply bg-gradient-to-br from-australian-mint to-wattle;
  }
  .social-media .sub-title,
  .social-media .title {
    @apply bg-gradient-to-t from-australian-mint to-wattle bg-clip-text text-transparent;
  }
  .fun-stuff,
  .legend-table__text.fun-stuff::before {
    @apply bg-gradient-to-br from-wild-watermelon to-chardonnay;
  }
  .fun-stuff .sub-title,
  .fun-stuff .title {
    @apply bg-gradient-to-t from-wild-watermelon to-chardonnay bg-clip-text text-transparent;
  }
  .personal-development,
  .legend-table__text.personal-development::before {
    @apply bg-gradient-to-br from-medium-turquoise to-cornflower-blue;
  }
  .personal-development .sub-title,
  .personal-development .title {
    @apply bg-gradient-to-t from-medium-turquoise to-cornflower-blue bg-clip-text text-transparent;
  }
  .serious-work,
  .legend-table__text.serious-work::before {
    @apply bg-gradient-to-br from-limeade to-conifer;
  }
  .serious-work .sub-title,
  .serious-work .title {
    @apply bg-gradient-to-t from-limeade to-conifer bg-clip-text text-transparent;
  }
  .legend-table {
    @apply p-4 md:text-center text-sm mt-8 mx-auto mb-0;
  }
  .legend-table__marker {
    @apply w-6 h-6 mt-1.5 mx-1.5 mb-4 inline-block align-middle shadow-[0_5px_1px_rgba(0,_0,_0,_0.15)];
  }
  .legend-table__text {
    @apply block md:inline-block align-middle mt-0 mr-6 ml-1.5 mb-1.5 bg-gradient-to-t from-very-light-grey to-whisper bg-clip-text text-transparent;
  }
  .legend-table__text::before {
    @apply content-[''] w-6 h-6 mr-3 mb-0.5 inline-block align-middle shadow-[0_5px_1px_rgba(0,_0,_0,_0.15)];
  }
  .legend-table__direction {
    @apply inline-block my-2.5 mt-[15px] mb-2.5 bg-gradient-to-t from-very-light-grey to-whisper bg-clip-text text-transparent;
  }

  .empty-spacer-0 {
    @apply col-span-7 col-start-2 row-start-1 row-end-auto;
  }
  .empty-spacer-1 {
    @apply col-span-4 col-start-3 row-start-2 row-end-auto;
  }
  .empty-spacer-2 {
    @apply col-start-1 row-start-5 row-end-auto;
  }
}
